// eslint-disable-next-line no-restricted-syntax
import mixpanel from 'mixpanel-browser';

function initMixpanel(mixpanelToken) {
  // eslint-disable-next-line no-console
  console.log('mixpanelToken', typeof mixpanel);
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
    autocapture: true,
    ip: true,
    track_marketing: true,
  });
}

const withMixpanel =
  func =>
  (...args) => {
    const mixpanelToken = process.env.REACT_APP_DAVINCI_FRONTEND_MIXPANEL_TOKEN;
    if (mixpanelToken) {
      initMixpanel(mixpanelToken);
      return func(...args);
    }
    // eslint-disable-next-line no-console
    console.log('mixpanelToken is undefined');
    return null;
  };

export default {
  identify: withMixpanel(id => {
    mixpanel.identify(id);
  }),
  alias: withMixpanel(id => {
    mixpanel.alias(id);
  }),
  track: withMixpanel((name, props) => {
    mixpanel.track(name, props);
  }),
  people: {
    set: withMixpanel(props => {
      mixpanel.people.set(props);
    }),
  },
};
