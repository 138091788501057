import { useArtifactStorageApi, useFinanceApi, useRecruitmentApi } from 'api';

type Thumbnails = Dictionary<string>;

export interface ArtifactThumbnailService {
  getThumbnails: (id: string) => Promise<Dictionary<string>>;
  getArtifactsInfo: (id: string) => Promise<{ artifacts: Dictionary<Thumbnails> }>;

  getInvoiceThumbnails: (id: string) => Promise<Dictionary<string>>;
  getInvoiceArtifactsInfo: (id: string) => Promise<{ artifacts: Dictionary<Thumbnails> }>;

  getCandidateThumbnails: (id: string) => Promise<Dictionary<string>>;
  getCandidateArtifactsInfo: (id: string) => Promise<{ artifacts: Dictionary<Thumbnails> }>;
}

const useArtifactThumbnailService = (): ArtifactThumbnailService => {
  const { sendGetRequest } = useArtifactStorageApi();
  const { sendGetRequest: sendFinanceGetRequest } = useFinanceApi();
  const { sendGetRequest: sendRecruitmentGetRequest } = useRecruitmentApi();

  const getArtifactsInfo = async (id: string) => {
    const response = await sendGetRequest(`/artifacts/${id}/thumbnails`);

    return response.json();
  };

  const getInvoiceArtifactsInfo = async (id: string) => {
    const response = await sendFinanceGetRequest(`/invoices/${id}/artifacts/thumbnails`);

    return response.json();
  };

  const getCandidateArtifactsInfo = async (id: string) => {
    const response = await sendRecruitmentGetRequest(`/candidates/artifacts/${id}/thumbnails`);

    return response.json();
  };

  const getThumbnails = async (id: string) => {
    const { artifacts = {} } = await getArtifactsInfo(id);

    return artifacts[id]?.thumbnails || {};
  };

  const getInvoiceThumbnails = async (id: string) => {
    const { artifacts = {} } = await getInvoiceArtifactsInfo(id);

    return artifacts[id]?.thumbnails || {};
  };

  const getCandidateThumbnails = async (id: string) => {
    const { artifacts = {} } = await getCandidateArtifactsInfo(id);

    return artifacts[id]?.thumbnails || {};
  };

  return {
    getThumbnails,
    getInvoiceThumbnails,
    getArtifactsInfo,
    getInvoiceArtifactsInfo,
    getCandidateArtifactsInfo,
    getCandidateThumbnails,
  };
};

export default useArtifactThumbnailService;
