import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useArtifactService from 'artifact/artifactService';
import useAsyncValue from 'shared/asyncHelper';
import styles from './CandidateResumeBadge.module.scss';
import useArtifactDownloadHelper from 'artifact/shared/artifactDownloadHelper';
import FileName from 'artifact/shared/field/layout/AttachmentFieldLayout/FileName';
import DownloadIcon from 'uibuilder/AppIcon/Icons/DownloadIcon';

export interface AttachmentsListFieldProps {
  value?: string[];
  label?: string;
  isDownloadAvailable?: boolean;
  isOneArtifact?: boolean;
  width?: number;
}

const CandidateResumeBadge: React.FC<AttachmentsListFieldProps> = ({
  value: inputValue = null,
  label: propsLabel = null,
  isDownloadAvailable = true,
  isOneArtifact = false,
}) => {
  const { data } = useShowContext();

  const { getArtifactsDataByIds } = useArtifactService({ isCanidateService: true });
  const { download } = useArtifactDownloadHelper({ isCanidateService: true });
  const attachmentsIds = (data && data.getValueBySource('lastUploadedResumeIds')) || [];

  const attachments = useAsyncValue(async () =>
    getArtifactsDataByIds(isOneArtifact ? [attachmentsIds] : attachmentsIds),
  );

  const onDownloadClickHandler = async (fileId: number | string, fileTitle?: string | undefined) => {
    await download(fileId, fileTitle);
  };

  if (!!attachments && attachments.length > 0) {
    const file = attachments[attachments.length - 1];
    const { id, title } = file;
    const elementId = `view-preview-button-${id}`;
    const handleClick = () => onDownloadClickHandler(id, title);
    const customFileName = 'Resume';
    return (
      <button
        key={id}
        className={styles.CandidateResumeBadge}
        type="button"
        onClick={handleClick}
        id={elementId}
        data-testid="artifact-open-preview"
      >
        {customFileName || <FileName title={title} />}
        <DownloadIcon sx={{ width: '21px', height: '15px' }} />
      </button>
    );
  }
  return null;
};

export default CandidateResumeBadge;
