import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useArtifactService from 'artifact/artifactService';
import getLabel from 'shared/uibuilder/helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import useArtifactDownloadHelper from 'artifact/shared/artifactDownloadHelper';
import useArtifactPreviewHelper from 'artifact/shared/artifactPreviewHelper';

export interface AttachmentsListFieldProps {
  source?: string;
  value?: string[];
  label?: string;
  isDownloadAvailable?: boolean;
  isOneArtifact?: boolean;
  width?: number;
  isVisible?: boolean | ((data: any) => boolean);
  isCanidateService?: boolean;
}

const AttachmentsListField: React.FC<AttachmentsListFieldProps> = ({
  source = '',
  value: inputValue = null,
  label: propsLabel = null,
  isDownloadAvailable = true,
  isOneArtifact = false,
  isCanidateService = false,
}) => {
  const { AttachmentsListLayout, BaseFieldLayout } = useUiTheme();

  const { data } = useShowContext();
  const label = getLabel(propsLabel, source);
  const { getArtifactsDataByIds, getThumbnail } = useArtifactService({ isCanidateService });
  const attachmentsIds = inputValue || (data && data.getValueBySource(source)) || [];

  const attachments = useAsyncValue(async () =>
    getArtifactsDataByIds(isOneArtifact ? [attachmentsIds] : attachmentsIds),
  );

  const { download } = useArtifactDownloadHelper({ isCanidateService });
  const { openPreview } = useArtifactPreviewHelper({ isCanidateService });

  return attachments ? (
    <AttachmentsListLayout
      attachments={attachments}
      label={label}
      downloadFile={download}
      getThumbnail={getThumbnail}
      openPreview={openPreview}
      isDownloadAvailable={isDownloadAvailable}
      className="mb-6"
    />
  ) : (
    <BaseFieldLayout label={label} value={<InputLoader />} />
  );
};

export default AttachmentsListField;
